import { useMutation, useQuery } from '../swrHooks';

import { GetListPlaceRequest } from '@/models/Place/PlaceRequest';
import { GetListPlaceResponse } from '@/models/Place/PlaceResponse';
import { BaseAxiosResponseGetAll } from '@/models/globalInterface';

export const useGetListPlaceQuery = (request: GetListPlaceRequest) => {
    return useQuery<GetListPlaceRequest, BaseAxiosResponseGetAll<GetListPlaceResponse>>({
        method: 'GET',
        url: `${process.env.NEXT_PUBLIC_API_URL}/api/places/google-autocomplete`,
        request: request,
    });
};

export const useGetListPlaceMutation = () => {
    return useMutation<GetListPlaceRequest, BaseAxiosResponseGetAll<GetListPlaceResponse>>({
        method: 'POST',
        url: `${process.env.NEXT_PUBLIC_API_URL}/api/places/google-autocomplete`,
    });
};
