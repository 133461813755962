'use client';
import useOnClickOutside from '@/hooks/useOnClickOutside';
import { RateSearchFormData } from '@/models/Quotation/QuotationFormData';
import { getLocalTimeZone, parseAbsoluteToLocal, today } from '@internationalized/date';
import { Calendar } from '@heroui/calendar';
import { Input } from '@heroui/input';
import { I18nProvider } from '@react-aria/i18n';
import { useRef, useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { FaCalendar } from '@react-icons/all-files/fa/FaCalendar';
import { FORMAT_ISO_DATE_TIME } from '@/constants/date-time';
import {useDeviceDetect} from "@/hooks/useDeviceDetect";
import dayjs from '@/utils/localizeDayjs';

type Props = {};

const DepartureDatePicker = (props: Props) => {
    const { control, watch, setFocus } = useFormContext<RateSearchFormData>();

    const optionsRef = useRef<HTMLDivElement>(null);

    const [visibleOptions, setVisibleOptions] = useState<boolean>(false);

    useOnClickOutside(optionsRef, () => {
        setVisibleOptions(false);
    });
    const { isMobile } = useDeviceDetect();

    const now = today(getLocalTimeZone());

    return (
        <div className='relative flex-1'>
            <Controller
                control={control}
                name='Date'
                rules={{}}
                render={({ field: { ref } }) => {
                    return (
                        <>
                            <Input
                                ref={ref}
                                fullWidth
                                size='lg'
                                startContent={<FaCalendar className='text-gray-800' size={14} />}
                                readOnly
                                value={dayjs(watch('Date')).format('L')}
                                classNames={{
                                    base: 'peer',
                                    label: 'text-gray-800',
                                    input: 'bg-white text-gray-800 placeholder:text-gray-400 font-medium',
                                    innerWrapper: 'bg-transparent',
                                    inputWrapper:
                                        'rounded-xl md:shadow-none bg-white group-data-[focus=true]:bg-white group-data-[focus=true]:outline-primary !cursor-text',
                                }}
                                placeholder={'dd/mm/yyyy'}
                                onFocus={() => {
                                    setVisibleOptions(true);
                                }}
                                onClick={() => {
                                    setVisibleOptions(true);
                                }}
                            />

                            {visibleOptions && (
                                <div
                                    ref={optionsRef}
                                    className='absolute z-dropdown top-full right-0 left-auto md:left-0 md:right-auto shadow-1 mt-2'
                                >
                                    <Controller
                                        control={control}
                                        name='Date'
                                        rules={{}}
                                        render={({ field: { name, onBlur, onChange, ref, value, disabled } }) => {
                                            return (
                                                <I18nProvider locale='vi-VN'>
                                                    <Calendar
                                                        aria-label='Date (International Calendar)'
                                                        visibleMonths={isMobile ? 1 : 2}
                                                        // calendarWidth={'100%'}
                                                        showShadow={false}
                                                        value={parseAbsoluteToLocal(value)}
                                                        onChange={(value) => {
                                                            onChange(
                                                                dayjs(value.toAbsoluteString())
                                                                    .tz(dayjs.tz.guess())
                                                                    .startOf('day')
                                                                    .format(FORMAT_ISO_DATE_TIME),
                                                            );
                                                            setVisibleOptions(false);
                                                            setFocus('ShipmentDisplayInput');
                                                        }}
                                                        topContent={
                                                            <div className='w-full py-1 px-3 flex gap-2 items-center bg-white justify-center border-b border-gray-100'>
                                                                <span className='text-sm'>
                                                                    Ngày khởi hành dự kiến (ETD)
                                                                </span>
                                                            </div>
                                                        }
                                                        // isDateUnavailable={(date) => {
                                                        //     // console.log(date.day);
                                                        //     // console.log(date);
                                                        //     // const now = today(getLocalTimeZone());
                                                        //     // console.log(now.);
                                                        //     return date.day < now.day;
                                                        // }}
                                                        minValue={now}
                                                        classNames={{
                                                            cell: '',
                                                            cellButton: '',
                                                            grid: '',
                                                        }}
                                                    />
                                                </I18nProvider>
                                            );
                                        }}
                                    />
                                </div>
                            )}
                        </>
                    );
                }}
            />
        </div>
    );
};

export default DepartureDatePicker;
