'use client';
import SuggestionPortAndLocation from '@/components/common/SuggestionPortAndLocation';
import { RecentSearchDefaultData } from '@/constants/recent-search-default';
import useLocalStorage from '@/hooks/useLocalStorage';
import { ShippingSuggestionTypeEnum } from '@/models/Enum.types';
import { QuotationQueryUrlModel, RateSearchFormData } from '@/models/Quotation/QuotationFormData';
import { QuotationSearchQuery, QuotationSearchRequest } from '@/models/Quotation/QuotationRequest';
import { searchDateGuard } from '@/utils/dates';
import dayjs from '@/utils/localizeDayjs';
import { Button } from '@heroui/button';
import { Divider } from '@heroui/divider';
import { FaAnchor } from '@react-icons/all-files/fa/FaAnchor';
import { FaArrowRight } from '@react-icons/all-files/fa/FaArrowRight';
import { FaCity } from '@react-icons/all-files/fa/FaCity';
import { FaPlane } from '@react-icons/all-files/fa/FaPlane';
import { FiArrowUpRight } from '@react-icons/all-files/fi/FiArrowUpRight';
import { IoReload } from '@react-icons/all-files/io5/IoReload';
import { IoSearch } from '@react-icons/all-files/io5/IoSearch';
import { IoSwapHorizontal } from '@react-icons/all-files/io5/IoSwapHorizontal';
import { MdLocationOn } from '@react-icons/all-files/md/MdLocationOn';
import { useTranslations } from 'next-intl';
import Link from 'next/link';
import { useRouter, useSearchParams } from 'next/navigation';
import qs from 'qs';
import { useEffect } from 'react';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import DepartureDatePicker from './DepartureDatePicker';
import ShippingAndTransportType from './ShippingAndTransportType';
import { useDeviceDetect } from '@/hooks/useDeviceDetect';

type Props = {
    isHomePage?: boolean;
    isLoading?: boolean;
    onResearch?: (data: RateSearchFormData) => void;
    initSessionData?: QuotationSearchRequest;
};

const RatesSearch = (props: Props) => {
    //#region props
    const { isLoading, isHomePage = false, onResearch, initSessionData } = props;
    //#endregion

    //#region hooks
    const t = useTranslations('home');
    const router = useRouter();
    const searchParams = useSearchParams();
    const [recentSearches, setRecentSearches] = useLocalStorage<Array<RateSearchFormData>>(
        'RECENT_SEARCHES_V2',
        RecentSearchDefaultData,
    );
    const { isDesktop } = useDeviceDetect();
    //#endregion

    const parsedSearchQuery = qs.parse(searchParams.toString()) as Partial<QuotationSearchQuery>;

    //#region form
    const formControl = useForm<RateSearchFormData>({
        mode: 'onSubmit',
        criteriaMode: 'firstError',
        reValidateMode: 'onSubmit',
        defaultValues: {
            IsAdvanceSearch: parsedSearchQuery.IsAdvanceSearch === 'true',
            TypeOfShipment: parsedSearchQuery.TypeOfShipment || 'FCL',
            TransportationBy: parsedSearchQuery.TransportationBy || 'Sea',
            ContainerType: parsedSearchQuery.ContainerType || '20DC',
            Weight: parsedSearchQuery.Weight || '1',
            Volume: parsedSearchQuery.Volume || '1',
            GrossWeight: parsedSearchQuery.GrossWeight || '500',
            Date: searchDateGuard(parsedSearchQuery.Date),
            DayAHead: parsedSearchQuery.DayAHead || '14',
            From: {
                PlaceId:
                    parsedSearchQuery.FromPlaceId || (initSessionData ? initSessionData?.From?.PlaceId : undefined),
                Name: parsedSearchQuery.FromName || (initSessionData ? initSessionData?.From?.Code : ''),
                Code: parsedSearchQuery.FromCode || (initSessionData ? initSessionData.From.Code : ''),
                Type: parsedSearchQuery.FromType || ShippingSuggestionTypeEnum.NONE,
            },
            To: {
                PlaceId: parsedSearchQuery.ToPlaceId ?? (initSessionData ? initSessionData.To.PlaceId : undefined),
                Name: parsedSearchQuery.ToName ?? (initSessionData ? initSessionData.To.Code : ''),
                Code: parsedSearchQuery.ToCode ?? (initSessionData ? initSessionData.To.Code : ''),
                Type: parsedSearchQuery.ToType || ShippingSuggestionTypeEnum.NONE,
            },
            ShipmentDetail: parsedSearchQuery?.ShipmentDetail || [
                {
                    ContainerType: '20DC',
                    Weight: '18000',
                    Quantity: '1',
                },
            ],

            CustomsClearance: parsedSearchQuery?.CustomsClearance === 'true',
            InspectionServices: parsedSearchQuery?.InspectionServices === 'true',
            Insurance: parsedSearchQuery?.Insurance === 'true',
            OnlyLocalCharges: parsedSearchQuery?.OnlyLocalCharges === 'true',
        },
    });

    const { handleSubmit, setValue, watch, getValues, reset } = formControl;
    //#endregion

    //#region handlers
    const handleSaveRecentSearch = (data: RateSearchFormData) => {
        if (recentSearches?.length < 3) {
            setRecentSearches([data, ...recentSearches]);
        } else {
            setRecentSearches([data, ...recentSearches].slice(0, 3));
        }
    };
    const handleSubmitSearch: SubmitHandler<RateSearchFormData> = (data) => {
        const isAdvanceSearch = data.IsAdvanceSearch;
        handleSaveRecentSearch(data);

        if (isAdvanceSearch) {
            const queryPayload: QuotationQueryUrlModel = {
                Date: searchDateGuard(data?.Date),

                FromPlaceId: data.From.PlaceId,
                FromName: data.From.Name,
                FromCode: data.From.Code,
                FromType: data.From.Type,

                ToPlaceId: data.To.PlaceId,
                ToName: data.To.Name,
                ToCode: data.To.Code,
                ToType: data.To.Type,

                IsAdvanceSearch: data?.IsAdvanceSearch,
                TransportationBy: data?.TransportationBy,
                TypeOfShipment: data?.TypeOfShipment,
                CustomsClearance: data?.CustomsClearance,
                InspectionServices: data?.InspectionServices,
                Insurance: data?.Insurance,
                OnlyLocalCharges: data?.OnlyLocalCharges,
                ShipmentDetail: data?.ShipmentDetail,
                DayAHead: data?.DayAHead,
            };

            const searchQuery = qs.stringify(queryPayload);

            if (isHomePage) router.push(`/quotations?${searchQuery}`);
            else router.replace(`/quotations?${searchQuery}`);
        } else {
            const queryPayload: QuotationQueryUrlModel = {
                Date: searchDateGuard(data?.Date),

                FromPlaceId: data.From.PlaceId,
                FromName: data.From.Name,
                FromCode: data.From.Code,
                FromType: data.From.Type,

                ToPlaceId: data.To.PlaceId,
                ToName: data.To.Name,
                ToCode: data.To.Code,
                ToType: data.To.Type,

                IsAdvanceSearch: data?.IsAdvanceSearch,
                TransportationBy: data?.TransportationBy,
                TypeOfShipment: data?.TypeOfShipment,
                ContainerType: data?.ContainerType,
                // Volume: data?.Volume,
                // Weight: data?.Weight,
                // GrossWeight: data?.GrossWeight,
                DayAHead: data?.DayAHead,
            };
            const searchQuery = qs.stringify(queryPayload);

            if (isHomePage) router.push(`/quotations?${searchQuery}`);
            else router.replace(`/quotations?${searchQuery}`);
        }
        onResearch?.(data);
    };
    //#endregion

    //#region renders
    const renderInputIcon = (placeType: ShippingSuggestionTypeEnum) => {
        switch (placeType) {
            case ShippingSuggestionTypeEnum.PORT:
                return <FaAnchor className='shrink-0' />;
            case ShippingSuggestionTypeEnum.CITY:
                return <FaCity className='shrink-0' />;
            case ShippingSuggestionTypeEnum.AIRPORT:
                return <FaPlane className='shrink-0' />;
            default:
                return <MdLocationOn className='shrink-0' />;
        }
    };

    useEffect(() => {
        if (
            initSessionData &&
            (!parsedSearchQuery.FromName ||
                !parsedSearchQuery.ToName ||
                !parsedSearchQuery.ContainerType ||
                !parsedSearchQuery.TypeOfShipment ||
                !parsedSearchQuery.Date ||
                !parsedSearchQuery.TransportationBy ||
                !parsedSearchQuery.DayAHead ||
                parsedSearchQuery.FromCode !== initSessionData?.From.Code ||
                parsedSearchQuery.FromType?.toLowerCase() !== initSessionData.From.Type.toLowerCase() ||
                parsedSearchQuery.ToCode !== initSessionData?.To.Code ||
                parsedSearchQuery.ToType?.toLowerCase() !== initSessionData.To.Type.toLowerCase() ||
                parsedSearchQuery.ContainerType !== initSessionData?.ContainerTypeCode ||
                parsedSearchQuery.TypeOfShipment !== initSessionData?.TypeOfShipment ||
                parsedSearchQuery.Date !== initSessionData?.Date ||
                parsedSearchQuery.TransportationBy.toLowerCase() !== initSessionData?.TransportationBy.toLowerCase() ||
                parsedSearchQuery.DayAHead !== String(initSessionData.DayAHead || '14'))
        ) {
            const searchFormData = {
                TransportationBy: initSessionData.TransportationBy,
                Date: searchDateGuard(initSessionData.Date),
                DayAHead: String(initSessionData.DayAHead || '14'),
                From: {
                    PlaceId: initSessionData.From.PlaceId,
                    Name: parsedSearchQuery?.FromName || initSessionData.From.Code || '',
                    Code: initSessionData.From.Code,
                    Type: (initSessionData.From.Type.charAt(0) +
                        initSessionData.From.Type.slice(1).toLowerCase()) as ShippingSuggestionTypeEnum,
                },
                To: {
                    PlaceId: initSessionData.To.PlaceId,
                    Name: parsedSearchQuery?.ToName || initSessionData.To.Code || '',
                    Code: initSessionData.To.Code,
                    Type: (initSessionData.To.Type.charAt(0) +
                        initSessionData.To.Type.slice(1).toLowerCase()) as ShippingSuggestionTypeEnum,
                },
                ContainerType: initSessionData.ContainerTypeCode,
                TypeOfShipment: initSessionData.TypeOfShipment,
                IsAdvanceSearch: parsedSearchQuery?.IsAdvanceSearch === 'true' || false,
                ShipmentDetail: parsedSearchQuery?.ShipmentDetail || [
                    {
                        ContainerType: '20DC',
                        Weight: '18000',
                        Quantity: '1',
                    },
                ],
                CustomsClearance: parsedSearchQuery?.CustomsClearance === 'true',
                InspectionServices: parsedSearchQuery?.InspectionServices === 'true',
                Insurance: parsedSearchQuery?.Insurance === 'true',
                OnlyLocalCharges: parsedSearchQuery?.OnlyLocalCharges === 'true',
                Weight: parsedSearchQuery.Weight || '1',
                Volume: parsedSearchQuery.Volume || '1',
                GrossWeight: parsedSearchQuery.GrossWeight || '500',
            };
            const searchRequestQuery: QuotationQueryUrlModel = {
                Date: searchDateGuard(initSessionData.Date),
                FromPlaceId: initSessionData.From.PlaceId,
                FromName: parsedSearchQuery?.FromName || initSessionData.From.Code,
                FromCode: initSessionData.From.Code,
                FromType:
                    ((initSessionData.From.Type.charAt(0) +
                        initSessionData.From.Type.slice(1).toLowerCase()) as ShippingSuggestionTypeEnum) ||
                    ShippingSuggestionTypeEnum.NONE,

                ToPlaceId: initSessionData.To.PlaceId,
                ToName: parsedSearchQuery?.ToName || initSessionData.To.Code,
                ToCode: initSessionData.To.Code,
                ToType:
                    ((initSessionData.To.Type.charAt(0) +
                        initSessionData.To.Type.slice(1).toLowerCase()) as ShippingSuggestionTypeEnum) ||
                    ShippingSuggestionTypeEnum.NONE,

                TransportationBy:
                    initSessionData.TransportationBy.charAt(0) +
                    initSessionData.TransportationBy.slice(1).toLowerCase(),
                TypeOfShipment: initSessionData.TypeOfShipment,
                IsAdvanceSearch: false,
                ContainerType: initSessionData.ContainerTypeCode,

                DayAHead: String(initSessionData.DayAHead || '14'),
                SessionId: parsedSearchQuery.SessionId,
            };
            const searchRequest = qs.stringify(Object.assign(parsedSearchQuery, searchRequestQuery));

            formControl.reset(searchFormData);
            router.replace(`/quotations?${searchRequest}`);
        }
    }, [initSessionData]);

    const renderRecentSearch = () => {
        if (recentSearches?.length === 0) {
            return null;
        }
        return (
            <div className='flex flex-col'>
                <div className='p-3 pt-4'>
                    <span className='text-[10px] font-bold text-gray-800 leading-3'>{t('recentSearches')}</span>
                </div>

                <div className='flex flex-col gap-0.5'>
                    {recentSearches?.map((item, index) => {
                        const date = new Date(item.Date);
                        return (
                            <div
                                className='p-3 flex gap-2 items-center hover:bg-primary-50 rounded-md cursor-pointer group duration-250'
                                key={`recent-${index}`}
                                onClick={() => {
                                    reset({
                                        ...item,
                                        Date: searchDateGuard(item.Date),
                                    });
                                }}
                            >
                                <IoReload className='group-hover:rotate-[360deg] duration-250' />

                                <div className='flex gap-1 items-center'>
                                    {/* <FaAnchor /> */}
                                    {renderInputIcon(item.From.Type)}
                                    <span className='text-sm font-semibold text-gray-800 max-w-36 text-ellipsis'>
                                        {`${item.From.Name}`}
                                    </span>
                                </div>

                                <FaArrowRight size={10} className='shrink-0' />

                                <div className='flex gap-1 items-center'>
                                    {/* <FaAnchor /> */}
                                    {renderInputIcon(item.To.Type)}
                                    <span className='text-sm font-semibold text-gray-800 line-clamp-1'>{`${item.To.Name}`}</span>
                                </div>

                                <div className='hidden md:flex gap-2 items-center text-xs text-gray-500'>
                                    <span>{dayjs(date).format('L')}</span>
                                    <span>{item.ContainerType}</span>
                                </div>
                            </div>
                        );
                    })}
                </div>
            </div>
        );
    };
    //#endregion

    return (
        <FormProvider {...formControl}>
            <div className='flex flex-col md:flex-row gap-2.5 md:items-center w-full md:bg-white md:p-1.5 md:rounded-2xl md:shadow-1 relative'>
                <div className='relative md:flex-1 flex flex-col md:flex-row md:items-center gap-2.5'>
                    <SuggestionPortAndLocation
                        flatInput
                        nameId='From'
                        keyId='From.PlaceId'
                        autoFocus
                        recentSearches={renderRecentSearch()}
                    />

                    <Button
                        size='sm'
                        isIconOnly
                        color='primary'
                        startContent={<IoSwapHorizontal size={18} strokeWidth={1} />}
                        className='md:!w-6 md:!h-6 md:!min-w-6 bg-gray-50 md:bg-primary-50/70 absolute right-2 top-1/2 z-10 -translate-y-1/2 rotate-90 md:relative md:top-0 md:right-0 md:translate-y-0 md:rotate-0'
                        variant='light'
                        isDisabled={!watch('From.PlaceId') && !watch('To.PlaceId')}
                        aria-label='switch-swap-location'
                        onPress={() => {
                            const { From: currentFrom, To: currentTo } = getValues();

                            setValue('From', currentTo);
                            setValue('From.PlaceId', currentTo.PlaceId);
                            setValue('To', currentFrom);
                            setValue('To.PlaceId', currentFrom.PlaceId);
                        }}
                    ></Button>

                    <SuggestionPortAndLocation
                        flatInput
                        nameId='To'
                        keyId='To.PlaceId'
                        recentSearches={renderRecentSearch()}
                    />
                </div>
                <Divider orientation='vertical' className='shrink-0 grow-0 h-7 hidden md:block' />

                <div className='md:flex-1 flex flex-col md:flex-row md:items-center gap-2.5'>
                    <div className='flex-1'>
                        <DepartureDatePicker />
                    </div>

                    <Divider orientation='vertical' className='shrink-0 grow-0 h-7 hidden md:block' />

                    <div className='flex-1'>
                        <ShippingAndTransportType />
                    </div>

                    <Button
                        aria-label='quotation-search-btn'
                        size='lg'
                        isIconOnly={isDesktop}
                        isLoading={isLoading}
                        color='primary'
                        className={`font-semibold ${
                            isHomePage && 'mt-2 md:mt-0'
                        } shrink-0 grow-0 md:!w-12 !min-w-12 md:px-0`}
                        onPress={() => {
                            handleSubmit(handleSubmitSearch)();
                        }}
                    >
                        <IoSearch size={20} />
                        <span className='md:hidden'>{t('search')}</span>
                    </Button>
                    {isHomePage && (
                        <>
                            <span className='text-center text-xs font-medium text-gray-700 md:hidden'>{t('or')}</span>

                            <Button
                                as={Link}
                                href='/shipping-request'
                                size='lg'
                                type='button'
                                color='primary'
                                variant='bordered'
                                className='font-semibold md:hidden'
                                endContent={<FiArrowUpRight size={20} strokeWidth={2.4} />}
                            >
                                {t('requestAQuote')}
                            </Button>
                        </>
                    )}
                </div>
            </div>
        </FormProvider>
    );
};

export default RatesSearch;
