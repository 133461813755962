import { useGetListPlaceMutation } from '@/apis/place/getListPlace';
import { useGetPlaceGeoCodeMutation } from '@/apis/place/getPlaceGeocode';
import useOnClickOutside from '@/hooks/useOnClickOutside';
import { LocationWarehouseTypeEnum, ShippingSuggestionTypeEnum } from '@/models/Enum.types';
import { GoogleAutocompletePlace, PlacePortAndCity } from '@/models/Place/PlaceModel';
import { Input } from "@heroui/input";
import { Spinner } from "@heroui/spinner";
import { FaAnchor } from '@react-icons/all-files/fa/FaAnchor';
import { FaCity } from '@react-icons/all-files/fa/FaCity';
import { FaPlane } from '@react-icons/all-files/fa/FaPlane';
import { MdLocationOn } from '@react-icons/all-files/md/MdLocationOn';
import { useTranslations } from 'next-intl';
import dynamic from 'next/dynamic';
import Image from 'next/image';
import { ReactNode, useEffect, useMemo, useRef, useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';

const LocationAndMap = dynamic(() => import('./LocationAndMap'));

type Props = {
    flatInput?: boolean;
    autoFocus?: boolean;
    nameId: 'From' | 'To' | 'Shipping.From' | 'Shipping.To' | 'Warehouse.WarehouseLocation';
    keyId:
        | 'From.PlaceId'
        | 'To.PlaceId'
        | 'Shipping.From.PlaceId'
        | 'Shipping.To.PlaceId'
        | 'Warehouse.WarehouseLocation.PlaceId';
    recentSearches?: ReactNode;
    optionsPlacement?: 'left' | 'right';
    showError?: boolean;
};

// type PlacePortAndCity = PlacePort & {
//     Type: ShippingSuggestionTypeEnum | LocationWarehouseTypeEnum;
//     Description?: string;
// };

const MAX_DISTANCE = 100; // km
const MIN_INPUT_LENGTH = 3;

const SuggestionPortAndLocation = (props: Props) => {
    //#region props
    const { autoFocus, nameId, keyId, flatInput, recentSearches, optionsPlacement = 'left', showError = false } = props;
    //#endregion

    //#region states
    const [visibleOptions, setVisibleOptions] = useState<boolean>(false);
    const optionsRef = useRef<HTMLDivElement>(null);
    const [selectedPlace, setSelectedPlace] = useState<GoogleAutocompletePlace>();
    //#endregion

    //#region form
    const { watch, setValue, setFocus } = useFormContext<
        Record<
            'Shipping.From' | 'Shipping.To' | 'From' | 'To' | 'Warehouse.WarehouseLocation',
            {
                Name: string;
                PlaceId?: string;
                Code?: string;
                Type: ShippingSuggestionTypeEnum | LocationWarehouseTypeEnum;
            }
        > & {
            Date: string;
            ContainerType?: string;
        }
    >();
    //#endregion

    //#region hooks
    const t = useTranslations('home');
    const tValidation = useTranslations('validation');

    useOnClickOutside(optionsRef, () => {
        setVisibleOptions(false);
    });
    //#endregion

    //#region services
    const {
        data: listPlace,
        trigger: getListPlace,
        isMutating: isLoading,
        reset: resetListPlace,
    } = useGetListPlaceMutation();
    const {
        data: listGeoCode,
        trigger: getPlaceGeoCode,
        isMutating: isLoadingGeoCode,
        reset: resetListGeoCode,
    } = useGetPlaceGeoCodeMutation();

    const listPlacePortAndCity = useMemo<{
        cityPorts: PlacePortAndCity[];
        airports: PlacePortAndCity[];
        ports: PlacePortAndCity[];
    }>(() => {
        const geoCodeData = listGeoCode?.data?.Data;
        if (geoCodeData && selectedPlace) {
            const listPlaceAirPort = geoCodeData?.Airports?.filter(
                (place) => parseFloat(place.Distance) < MAX_DISTANCE,
            )?.map<PlacePortAndCity>((place) => {
                return {
                    Id: place.Id,
                    Name: place.Name,
                    Description: t('airportOfCountry', { country: selectedPlace?.Country }),
                    Lat: place.Lat,
                    Lng: place.Lng,
                    CountryCode: place.CountryCode,
                    Type: ShippingSuggestionTypeEnum.AIRPORT,
                    Distance: place.Distance,
                    PortId: place.PortId,
                    Terminal: place.Terminal,
                    Unlocode: place.Unlocode,
                };
            });

            const listPlacePort = geoCodeData?.CountryPorts?.filter(
                (place) => parseFloat(place.Distance) < MAX_DISTANCE,
            )?.map<PlacePortAndCity>((place) => {
                return {
                    Id: place.Id,
                    Name: place.Name,
                    Description: t('portOfCountry', { country: selectedPlace?.Country }),
                    Lat: place.Lat,
                    Lng: place.Lng,
                    CountryCode: place.CountryCode,
                    Type: ShippingSuggestionTypeEnum.PORT,
                    Distance: place.Distance,
                    PortId: place.PortId,
                    Terminal: place.Terminal,
                    Unlocode: place.Unlocode,
                };
            });

            const currentCityPlace: PlacePortAndCity = {
                Id: geoCodeData?.CurrentCity?.Id,
                Lat: parseFloat(geoCodeData?.CurrentCity?.Latitude),
                Lng: parseFloat(geoCodeData?.CurrentCity?.Longitude),
                CountryCode: selectedPlace?.CountryCode || '',
                Description: t('cityOfCountry', { country: selectedPlace?.Country }), // `City of ${selectedPlace?.Country}`,
                Name: `${selectedPlace?.City}`,
                Type: ShippingSuggestionTypeEnum.CITY,
                Distance: '0',
                PortId: 0,
                Terminal: false,
                Unlocode: '',
            };
            return {
                airports: listPlaceAirPort,
                ports: listPlacePort,
                cityPorts: [currentCityPlace],
            };
            // return listPlacePort?.filter((place) => parseFloat(place.Distance) < MAX_DISTANCE);
            // return [currentCityPlace]
            //     .concat(listPlacePort)
            //     .concat(listPlaceAirPort)
            //     ?.filter((place) => parseFloat(place.Distance) < MAX_DISTANCE);
        } else {
            // return [];
            return {
                airports: [],
                ports: [],
                cityPorts: [],
            };
        }
    }, [listGeoCode, selectedPlace, t]);

    //#endregion

    //#region handlers
    const handleSelectPortAndCityPlace = (place: PlacePortAndCity) => {
        setValue(nameId, { PlaceId: place?.Id, Code: place?.Unlocode, Type: place.Type, Name: place.Name });
        setValue(keyId, place?.Id?.toString());

        setSelectedPlace(undefined);
        resetListPlace();
        resetListGeoCode();

        if (keyId === 'From.PlaceId') {
            setFocus('To.PlaceId');
        }

        if (keyId === 'To.PlaceId') {
            //@ts-ignore
            setFocus('Date');
        }
    };
    //#endregion

    //#region variables
    const inputValue = watch(nameId)?.Name;
    const placeId = watch(keyId);
    const placeInputValue = watch(nameId)?.Name;
    const placeType = watch(nameId)?.Type;

    const isSelectedPlace = Boolean(placeId);
    const isShowSearchingResult =
        Boolean(listPlace?.data) &&
        Boolean(placeInputValue.length >= MIN_INPUT_LENGTH) &&
        (!watch('From.PlaceId') || !watch('To.PlaceId') || !watch('Warehouse.WarehouseLocation'));
    const isShowRecentSearches =
        Boolean(!placeInputValue) &&
        (!watch('From.PlaceId') || !watch('To.PlaceId') || !watch('Warehouse.WarehouseLocation')) &&
        Boolean(recentSearches);
    //#endregion

    //#region effects

    useEffect(() => {
        // get google autocomplete places with search text input
        const timeoutId = setTimeout(() => {
            (async () => {
                if (selectedPlace || placeId) return;
                if (inputValue && inputValue?.length >= MIN_INPUT_LENGTH) {
                    await getListPlace({
                        key: inputValue,
                    });
                }
            })();
        }, 300);

        return () => {
            clearTimeout(timeoutId);
        };
    }, [getListPlace, inputValue, selectedPlace, placeId]);
    useEffect(() => {
        // get selected place ports and city
        if (selectedPlace) {
            (async () => {
                const { City, Code, Country, CountryCode, PlaceId, PlaceType, Region } = selectedPlace;
                await getPlaceGeoCode({
                    PlaceId,
                    CountryCode,
                    PlaceType,
                    Type: 'place_id',
                });
            })();
        }
    }, [getPlaceGeoCode, selectedPlace]);
    useEffect(() => {
        if (isSelectedPlace) {
            setVisibleOptions(false);
        } else if (placeInputValue) {
            setVisibleOptions(true);
        }
    }, [isSelectedPlace, placeInputValue, keyId, watch]);
    //#endregion

    //#region render display input icon
    const renderInputIcon = () => {
        switch (placeType) {
            case ShippingSuggestionTypeEnum.PORT:
                return <FaAnchor />;
            case ShippingSuggestionTypeEnum.CITY:
                return <FaCity />;
            case ShippingSuggestionTypeEnum.AIRPORT:
                return <FaPlane />;
            default:
                return <MdLocationOn size={22} />;
        }
    };
    //#endregion

    return (
        (<div className='relative md:static flex-1'>
            <Controller
                name={keyId}
                rules={{
                    required: tValidation('place.required'),
                }}
                render={({ field: { ref }, fieldState: { invalid, error } }) => {
                    return (
                        <Input
                            fullWidth
                            autoFocus={autoFocus}
                            size='lg'
                            ref={ref}
                            startContent={renderInputIcon()}
                            value={watch(nameId)?.Name}
                            autoComplete='off'
                            onValueChange={(value) => {
                                if (placeId) {
                                    setValue(`${keyId}`, '');
                                    setValue(`${nameId}.Type`, ShippingSuggestionTypeEnum.NONE);
                                }
                                setSelectedPlace(undefined);
                                setValue(`${nameId}.Name`, value);
                            }}
                            variant={flatInput ? 'flat' : 'bordered'}
                            classNames={{
                                label: 'text-gray-800',
                                input: 'bg-white text-gray-800 placeholder:text-gray-300 placeholder:font-medium font-medium',
                                innerWrapper: 'bg-transparent',
                                inputWrapper: [
                                    'rounded-xl md:shadow-none bg-white group-data-[focus=true]:bg-white group-data-[focus=true]:outline-primary !cursor-text',
                                    visibleOptions && 'outline-primary',
                                ],
                            }}
                            placeholder={t('placeSearchDes')}
                            onFocus={() => {
                                if (!placeId) setVisibleOptions(true);
                            }}
                            onClick={() => {
                                if (!placeId) setVisibleOptions(true);
                            }}
                            isInvalid={showError && invalid}
                            errorMessage={error?.message}
                        />
                    );
                }}
            />
            {visibleOptions && (
                <div
                    ref={optionsRef}
                    className={`${
                        Boolean(isShowSearchingResult || isLoading || isShowRecentSearches) && 'bg-white shadow-1'
                    } ${
                        optionsPlacement === 'right' ? 'right-0 left-0 lg:left-auto' : 'left-0 right-0 lg:right-auto'
                    } absolute md:min-w-[560px] z-dropdown top-full rounded-xl p-1 mt-2`}
                >
                    <div className='max-h-96 overflow-y-auto'>
                        {Boolean(isLoadingGeoCode || isLoading) ? (
                            <div className='h-20 w-full flex justify-center items-center'>
                                <Spinner color='primary' size='md' />
                            </div>
                        ) : (
                            <>
                                {isShowSearchingResult && (
                                    <>
                                        {selectedPlace ? (
                                            // ports and map
                                            (<LocationAndMap
                                                listGeoCode={listGeoCode?.data?.Data}
                                                listPlacePortAndCity={listPlacePortAndCity}
                                                handleSelectPortAndCityPlace={handleSelectPortAndCityPlace}
                                            />)
                                        ) : (
                                            // autocomplete places
                                            (<div className='flex flex-col'>
                                                {listPlace?.data?.Data?.map?.((place) => {
                                                    return (
                                                        <div
                                                            key={place.PlaceId}
                                                            className='py-2 px-4 flex gap-2.5 hover:bg-primary-50 rounded-md cursor-pointer'
                                                            onClick={() => {
                                                                setValue(`${nameId}.Name`, place.City);

                                                                setSelectedPlace(place);
                                                            }}
                                                        >
                                                            <Image
                                                                alt='United States'
                                                                src={`http://purecatamphetamine.github.io/country-flag-icons/3x2/${place.CountryCode}.svg`}
                                                                width={16}
                                                                height={10}
                                                            />
                                                            <div className='flex flex-col'>
                                                                <span className='text-sm font-medium text-gray-800'>{`${place.City}, ${place.CountryCode}`}</span>
                                                                <span className='text-xs text-gray-500'>
                                                                    {place.Country}
                                                                </span>
                                                            </div>
                                                        </div>
                                                    );
                                                })}
                                            </div>)
                                            // autocomplete places
                                        )}
                                    </>
                                )}

                                {isShowRecentSearches && recentSearches}
                            </>
                        )}
                    </div>
                </div>
            )}
        </div>)
    );
};

export default SuggestionPortAndLocation;
