import { ShippingSuggestionTypeEnum } from '@/models/Enum.types';
import { RateSearchFormData } from '@/models/Quotation/QuotationFormData';
import dayjs from 'dayjs';

export const RecentSearchDefaultData: Array<RateSearchFormData> = [
    {
        IsAdvanceSearch: false,
        TypeOfShipment: 'FCL',
        TransportationBy: 'Sea',
        ContainerType: '20DC',
        Weight: '1',
        Volume: '1',
        GrossWeight: '500',
        Date: dayjs().format('YYYY-MM-DDTHH:mm:ssZ'),
        DayAHead: '14',
        From: {
            PlaceId: 'P_19845',
            Code: 'VNHPH',
            Type: ShippingSuggestionTypeEnum.PORT,
            Name: 'Haiphong',
        },
        To: {
            PlaceId: 'P_11175',
            Code: 'NLRTM',
            Type: ShippingSuggestionTypeEnum.PORT,
            Name: 'Rotterdam',
        },
        ShipmentDetail: [
            {
                ContainerType: '20DC',
                Weight: '18000',
                Quantity: '1',
            },
        ],
        CustomsClearance: false,
        InspectionServices: false,
        Insurance: false,
        OnlyLocalCharges: false,
    },
    {
        IsAdvanceSearch: false,
        TypeOfShipment: 'FCL',
        TransportationBy: 'Sea',
        ContainerType: '40DC',
        Weight: '1',
        Volume: '1',
        GrossWeight: '500',
        Date: dayjs().format('YYYY-MM-DDTHH:mm:ssZ'),
        DayAHead: '14',
        From: {
            PlaceId: 'P_33937',
            Code: 'VNHCM',
            Type: ShippingSuggestionTypeEnum.PORT,
            Name: 'Ho Chi Minh City',
        },
        To: {
            PlaceId: 'P_16959',
            Code: 'USNYC',
            Type: ShippingSuggestionTypeEnum.PORT,
            Name: 'New York',
        },
        ShipmentDetail: [
            {
                ContainerType: '20DC',
                Weight: '18000',
                Quantity: '1',
            },
        ],
        CustomsClearance: false,
        InspectionServices: false,
        Insurance: false,
        OnlyLocalCharges: false,
    },
    {
        IsAdvanceSearch: false,
        TypeOfShipment: 'FCL',
        TransportationBy: 'Sea',
        ContainerType: '20DC',
        Weight: '1',
        Volume: '1',
        GrossWeight: '500',
        Date: dayjs().format('YYYY-MM-DDTHH:mm:ssZ'),
        DayAHead: '14',
        From: {
            PlaceId: 'P_33937',
            Code: 'VNHCM',
            Type: ShippingSuggestionTypeEnum.PORT,
            Name: 'Ho Chi Minh City',
        },
        To: {
            PlaceId: 'P_20356',
            Code: 'GBSOU',
            Type: ShippingSuggestionTypeEnum.PORT,
            Name: 'Southampton',
        },
        ShipmentDetail: [
            {
                ContainerType: '20DC',
                Weight: '18000',
                Quantity: '1',
            },
        ],
        CustomsClearance: false,
        InspectionServices: false,
        Insurance: false,
        OnlyLocalCharges: false,
    },
];
