import { ContainerGetByViewRequest } from '@/models/Container/ContainerRequest';
import { ContainerGetByViewResponse } from '@/models/Container/ContainerResponse';
import { BaseAxiosResponseGetAll } from '@/models/globalInterface';
import { useQuery } from '../swrHooks';

export const useGetByViewContainerQuery = ({
    request,
    skip,
}: {
    request?: ContainerGetByViewRequest;
    skip?: boolean;
}) => {
    return useQuery<ContainerGetByViewRequest, BaseAxiosResponseGetAll<ContainerGetByViewResponse>>({
        method: 'GET',
        url: skip ? '' : `${process.env.NEXT_PUBLIC_API_URL}/api/containers/get-by-view`,
        request: request,
        fetcherConfig: {
            cache: 'no-cache',
        },
    });
};
