import { ShippingTypeOption } from '@/models/Quotation/QuotationModel';

export const shippingTypeOptions: Array<ShippingTypeOption> = [
    {
        Id: '2',
        TName: 'fullConLoad', // key of next/intl translation
        Type: 'FCL',
        Mode: 'Sea',
        TModeName: 'sea', // key of next/intl translation
    },
    {
        Id: '1',
        TName: 'lessConLoad',
        Type: 'LCL',
        Mode: 'Sea',
        TModeName: 'sea',
        IsDisabled: true,
    },
    {
        Id: '32',
        TName: 'fullTruckLoad',
        Type: 'FTL',
        Mode: 'Road',
        IsDisabled: false,
        TModeName: 'road',
    },
    {
        Id: '33',
        TName: 'lessTruckLoad',
        Type: 'LTL',
        Mode: 'Road',
        IsDisabled: true,
        TModeName: 'road',
    },
    {
        Id: '43',
        TName: 'standardCargo',
        Type: 'SC',
        Mode: 'Air',
        IsDisabled: true,
        TModeName: 'air',
    },
    {
        Id: '42',
        TName: 'fullConLoad',
        Type: 'FCL',
        Mode: 'Rail',
        IsDisabled: true,
        TModeName: 'rail',
    },
];
