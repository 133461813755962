import { FORMAT_ISO_DATE_TIME } from '@/constants/date-time';
import dayjs from '@/utils/localizeDayjs';

/**
 * Kiểm tra và trả về ngày nếu ngày đó là hôm nay hoặc tương lai.
 * @param {string} date - Ngày cần kiểm tra (định dạng YYYY-MM-DD).
 * @returns {string} - Ngày hợp lệ ở định dạng ISO string hoặc ngày hiện tại nếu không hợp lệ.
 */
export const searchDateGuard = (date?: string): string => {
    const inputDate = dayjs(date);
    const today = dayjs().startOf('day');

    // Kiểm tra nếu ngày nhập >= ngày hôm nay
    if (inputDate.isSame(today) || inputDate.isAfter(today)) {
        return inputDate.tz(dayjs.tz.guess()).startOf('day').format(FORMAT_ISO_DATE_TIME); // Trả về định dạng ISO
    }

    return today.tz(dayjs.tz.guess()).format(FORMAT_ISO_DATE_TIME); // Trả về ngày hiện tại nếu ngày không hợp lệ
};
