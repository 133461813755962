export enum DistanceEnum {
    cm = 'cm',
    m = 'm',
    km = 'km',
    ft = 'ft',
    yd = 'yd',
    miles = 'miles',
    nmi = 'nmi',
}

export enum SizeEnum {
    cm = 'cm',
    m = 'm',
}

export enum HumidityUnitEnum {
    Percentage = '%',
}

export enum TemperatureUnitEnum {
    C = 'C',
    F = 'F',
}

export enum WeightUnitEnum {
    g = 'g',
    kg = 'kg',
    ton = 'ton',
    mt = 'mt',
}

export enum VolumeUnitEnum {
    m3 = 'm3',
    cm3 = 'cm3',
    litter = 'litter',
    mt = 'mt',
}
export enum CurrencyUnitEnum {
    VND = 'VND',
    USD = 'USD',
}
export enum StatusBookingEnum {
    NotFinish = 0,
    HoldOk = 1,
    WaitPayment = 2,
    Cancel = 3,
    PaymentSuccess = 4,
    IssueTicket = 5,
    Finish = 6,
    Continue = 7,
    GoodsImportSuccess = 8,
    Processing = 9,
    FullRefund = 10,
    PartialRefund = 11,
}
export enum PaymentBookingEnum {
    NOTFINISH = -1,
    WAITPAYMENT = 0,
    PAYMENTOK = 1,
    LIABILITIES = 2,
}
export enum InvoicePaymentEnum {
    Paid = 'Paid',
    Unpaid = 'Unpaid',
}

export enum LocationTypeEnum {
    CITY = 'CITY',
    PORT = 'PORT',
}
export enum ShippingSuggestionTypeEnum {
    CITY = 'City',
    PORT = 'Port',
    AIRPORT = 'Airport',
    NONE = 'None',
}

export enum ShippingActivityTypeEnum {
    EQUIPMENT = 'EQUIPMENT',
    TRANSPORT = 'TRANSPORT',
    DISCHARGE = 'DISCHARGE',
}

export enum ShipmentTypeEnum {
    FCL = 'FCL',
    LCL = 'LCL',
    FTL = 'FTL',
    LTL = 'LTL',
}

export enum TransportationTypeEnum {
    TRUCK = 'TRUCK',
    RAIL = 'RAIL',
    AIR = 'AIR',
    SEA = 'SEA',
}

export enum LocationWarehouseTypeEnum {
    CITY = 'City',
    PORT = 'Port',
    AIRPORT = 'Airport',
    NONE = 'None',
}

export enum RouteTypeEnum {
    Place = 'Place',
    Transiting = 'Transiting',
    Transporting = 'Transporting',
    OceanFreight = 'OceanFreight',
    Surcharge = 'Surcharge',
}

export enum CurrencyISOEnum {
    AED = <any>'784', // UAE Dirham
    AFN = <any>'971', // Afghan Afghani
    ALL = <any>'008', // Albanian Lek
    AMD = <any>'051', // Armenian Dram
    ANG = <any>'532', // Netherlands Antillean Guilder
    AOA = <any>'973', // Angolan Kwanza
    ARS = <any>'032', // Argentine Peso
    AUD = <any>'036', // Australian Dollar
    AWG = <any>'533', // Aruban Florin
    AZN = <any>'944', // Azerbaijani Manat
    BAM = <any>'977', // Bosnia and Herzegovina Convertible Mark
    BBD = <any>'052', // Barbadian Dollar
    BDT = <any>'050', // Bangladeshi Taka
    BGN = <any>'975', // Bulgarian Lev
    BHD = <any>'048', // Bahraini Dinar
    BIF = <any>'108', // Burundian Franc
    BMD = <any>'060', // Bermudian Dollar
    BND = <any>'096', // Brunei Dollar
    BOB = <any>'068', // Bolivian Boliviano
    BRL = <any>'986', // Brazilian Real
    BSD = <any>'044', // Bahamian Dollar
    BTN = <any>'064', // Bhutanese Ngultrum
    BWP = <any>'072', // Botswana Pula
    BYN = <any>'933', // Belarusian Ruble
    BZD = <any>'084', // Belize Dollar
    CAD = <any>'124', // Canadian Dollar
    CDF = <any>'976', // Congolese Franc
    CHF = <any>'756', // Swiss Franc
    CLP = <any>'152', // Chilean Peso
    CNY = <any>'156', // Chinese Yuan
    COP = <any>'170', // Colombian Peso
    CRC = <any>'188', // Costa Rican Colón
    CUP = <any>'192', // Cuban Peso
    CVE = <any>'132', // Cape Verdean Escudo
    CZK = <any>'203', // Czech Koruna
    DJF = <any>'262', // Djiboutian Franc
    DKK = <any>'208', // Danish Krone
    DOP = <any>'214', // Dominican Peso
    DZD = <any>'012', // Algerian Dinar
    EGP = <any>'818', // Egyptian Pound
    ERN = <any>'232', // Eritrean Nakfa
    ETB = <any>'230', // Ethiopian Birr
    EUR = <any>'978', // Euro
    FJD = <any>'242', // Fijian Dollar
    FKP = <any>'238', // Falkland Islands Pound
    FOK = <any>'234', // Faroese Króna
    GBP = <any>'826', // British Pound Sterling
    GEL = <any>'981', // Georgian Lari
    GGP = <any>'831', // Guernsey Pound
    GHS = <any>'936', // Ghanaian Cedi
    GIP = <any>'292', // Gibraltar Pound
    GMD = <any>'270', // Gambian Dalasi
    GNF = <any>'324', // Guinean Franc
    GTQ = <any>'320', // Guatemalan Quetzal
    GYD = <any>'328', // Guyanese Dollar
    HKD = <any>'344', // Hong Kong Dollar
    HNL = <any>'340', // Honduran Lempira
    HRK = <any>'191', // Croatian Kuna
    HTG = <any>'332', // Haitian Gourde
    HUF = <any>'348', // Hungarian Forint
    IDR = <any>'360', // Indonesian Rupiah
    ILS = <any>'376', // Israeli New Shekel
    IMP = <any>'833', // Isle of Man Pound
    INR = <any>'356', // Indian Rupee
    IQD = <any>'368', // Iraqi Dinar
    IRR = <any>'364', // Iranian Rial
    ISK = <any>'352', // Icelandic Króna
    JMD = <any>'388', // Jamaican Dollar
    JOD = <any>'400', // Jordanian Dinar
    JPY = <any>'392', // Japanese Yen
    KES = <any>'404', // Kenyan Shilling
    KGS = <any>'417', // Kyrgyzstani Som
    KHR = <any>'116', // Cambodian Riel
    KID = <any>'036', // Kiribati Dollar
    KMF = <any>'174', // Comorian Franc
    KRW = <any>'410', // South Korean Won
    KWD = <any>'414', // Kuwaiti Dinar
    KYD = <any>'136', // Cayman Islands Dollar
    KZT = <any>'398', // Kazakhstani Tenge
    LAK = <any>'418', // Lao Kip
    LBP = <any>'422', // Lebanese Pound
    LKR = <any>'144', // Sri Lankan Rupee
    LRD = <any>'430', // Liberian Dollar
    LSL = <any>'426', // Lesotho Loti
    LYD = <any>'434', // Libyan Dinar
    MAD = <any>'504', // Moroccan Dirham
    MDL = <any>'498', // Moldovan Leu
    MGA = <any>'969', // Malagasy Ariary
    MKD = <any>'807', // Macedonian Denar
    MMK = <any>'104', // Myanmar Kyat
    MNT = <any>'496', // Mongolian Tögrög
    MOP = <any>'446', // Macanese Pataca
    MRU = <any>'929', // Mauritanian Ouguiya
    MUR = <any>'480', // Mauritian Rupee
    MVR = <any>'462', // Maldivian Rufiyaa
    MWK = <any>'454', // Malawian Kwacha
    MXN = <any>'484', // Mexican Peso
    MYR = <any>'458', // Malaysian Ringgit
    MZN = <any>'943', // Mozambican Metical
    NAD = <any>'516', // Namibian Dollar
    NGN = <any>'566', // Nigerian Naira
    NIO = <any>'558', // Nicaraguan Córdoba
    NOK = <any>'578', // Norwegian Krone
    NPR = <any>'524', // Nepalese Rupee
    NZD = <any>'554', // New Zealand Dollar
    OMR = <any>'512', // Omani Rial
    PAB = <any>'590', // Panamanian Balboa
    PEN = <any>'604', // Peruvian Sol
    PGK = <any>'598', // Papua New Guinean Kina
    PHP = <any>'608', // Philippine Peso
    PKR = <any>'586', // Pakistani Rupee
    PLN = <any>'985', // Polish Złoty
    PYG = <any>'600', // Paraguayan Guaraní
    QAR = <any>'634', // Qatari Riyal
    RON = <any>'946', // Romanian Leu
    RSD = <any>'941', // Serbian Dinar
    RUB = <any>'643', // Russian Ruble
    RWF = <any>'646', // Rwandan Franc
    SAR = <any>'682', // Saudi Riyal
    SBD = <any>'090', // Solomon Islands Dollar
    SCR = <any>'690', // Seychellois Rupee
    SDG = <any>'938', // Sudanese Pound
    SEK = <any>'752', // Swedish Krona
    SGD = <any>'702', // Singapore Dollar
    SHP = <any>'654', // Saint Helena Pound
    SLL = <any>'694', // Sierra Leonean Leone
    SOS = <any>'706', // Somali Shilling
    SRD = <any>'968', // Surinamese Dollar
    SSP = <any>'728', // South Sudanese Pound
    STN = <any>'930', // São Tomé and Príncipe Dobra
    SYP = <any>'760', // Syrian Pound
    SZL = <any>'748', // Eswatini Lilangeni
    THB = <any>'764', // Thai Baht
    TJS = <any>'972', // Tajikistani Somoni
    TMT = <any>'934', // Turkmenistani Manat
    TND = <any>'788', // Tunisian Dinar
    TOP = <any>'776', // Tongan Paʻanga
    TRY = <any>'949', // Turkish Lira
    TTD = <any>'780', // Trinidad and Tobago Dollar
    TVD = <any>'036', // Tuvaluan Dollar
    TWD = <any>'901', // New Taiwan Dollar
    TZS = <any>'834', // Tanzanian Shilling
    UAH = <any>'980', // Ukrainian Hryvnia
    UGX = <any>'800', // Ugandan Shilling
    USD = <any>'840', // United States Dollar
    UYU = <any>'858', // Uruguayan Peso
    UZS = <any>'860', // Uzbekistani Soʻm
    VES = <any>'928', // Venezuelan Bolívar Soberano
    VND = <any>'704', // Vietnamese Đồng
    VUV = <any>'548', // Vanuatu Vatu
    WST = <any>'882', // Samoan Tala
    XAF = <any>'950', // Central African CFA Franc
    XCD = <any>'951', // East Caribbean Dollar
    XOF = <any>'952', // West African CFA Franc
    XPF = <any>'953', // CFP Franc
    YER = <any>'886', // Yemeni Rial
    ZAR = <any>'710', // South African Rand
    ZMW = <any>'967', // Zambian Kwacha
    ZWL = <any>'932', // Zimbabwean Dollar
}
