import { useMutation } from '../swrHooks';

import { GetGeocodeRequest } from '@/models/Place/PlaceRequest';
import { GetGeocodeResponse } from '@/models/Place/PlaceResponse';
import { BaseAxiosResponseGetAll } from '@/models/globalInterface';

export const useGetPlaceGeoCodeMutation = () => {
    return useMutation<GetGeocodeRequest, BaseAxiosResponseGetAll<GetGeocodeResponse>>({
        method: 'POST',
        url: `${process.env.NEXT_PUBLIC_API_URL}/api/places/google-geocode`,
    });
};
