import { DayAHeadOption } from '@/models/Quotation/QuotationModel';

export const dayAheadOptions: Array<DayAHeadOption> = [
    {
        Id: '1',
        TName: '1week',
        Value: 7,
    },
    {
        Id: '2',
        TName: '2weeks',
        Value: 14,
    },
    {
        Id: '3',
        TName: '3weeks',
        Value: 21,
    },
    {
        Id: '4',
        TName: '4weeks',
        Value: 28,
    },
];
