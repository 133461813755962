import { cookieStorageKeys } from '@/constants/storageKeys';
import dayjs from 'dayjs';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import { getClientCookie } from './client-cookie';
import relativeTime from 'dayjs/plugin/relativeTime'; // ES 2015

require('dayjs/locale/vi');
require('dayjs/locale/en');

dayjs.extend(relativeTime);
dayjs.extend(localizedFormat);
dayjs.extend(utc);
dayjs.extend(timezone);

const locale = getClientCookie(cookieStorageKeys.LOCALE);

dayjs.locale(locale || 'vi');

export default dayjs;
