import { useGetByViewContainerQuery } from '@/apis/container/getByViewContainer';
import { dayAheadOptions } from '@/constants/dayAhead';
import { shippingTypeOptions } from '@/constants/shippingTypes';
import useOnClickOutside from '@/hooks/useOnClickOutside';
import { RateSearchFormData } from '@/models/Quotation/QuotationFormData';
import { GroupShippingTypeOption } from '@/models/Quotation/QuotationModel';
import { numberFormat } from '@/utils/numberFormat';
import { Button } from '@heroui/button';
import { Checkbox } from '@heroui/checkbox';
import { Divider } from '@heroui/divider';
import { Input } from '@heroui/input';
import { Select, SelectItem, SelectSection } from '@heroui/select';
import { Tooltip } from '@heroui/tooltip';
import { AiOutlineClose } from '@react-icons/all-files/ai/AiOutlineClose';
import { AiOutlineMinus } from '@react-icons/all-files/ai/AiOutlineMinus';
import { AiOutlinePlus } from '@react-icons/all-files/ai/AiOutlinePlus';
import { BiCollapse } from '@react-icons/all-files/bi/BiCollapse';
import { BiExpand } from '@react-icons/all-files/bi/BiExpand';
import { FaPlane } from '@react-icons/all-files/fa/FaPlane';
import { FaPlus } from '@react-icons/all-files/fa/FaPlus';
import { FaShip } from '@react-icons/all-files/fa/FaShip';
import { FaTrain } from '@react-icons/all-files/fa/FaTrain';
import { FaTruckMoving } from '@react-icons/all-files/fa/FaTruckMoving';
import { IoInformationCircleOutline } from '@react-icons/all-files/io5/IoInformationCircleOutline';
import { IoTrashBin } from '@react-icons/all-files/io5/IoTrashBin';
import sumBy from 'lodash.sumby';
import { useTranslations } from 'next-intl';
import Link from 'next/link';
import { useMemo, useRef, useState } from 'react';
import { Controller, useFieldArray, useFormContext } from 'react-hook-form';
type Props = {};

const ShippingAndTransportType = (props: Props) => {
    // region states
    const [visibleOptions, setVisibleOptions] = useState<boolean>(false);
    const optionsRef = useRef<HTMLDivElement>(null);
    // end region

    // region hooks
    const t = useTranslations('home');
    const tReference = useTranslations('reference');
    const groupShippingTypes = useMemo(() => {
        return shippingTypeOptions?.reduce<Array<GroupShippingTypeOption>>((acc, cur) => {
            const shippingTypeIndex = acc.findIndex((item) => item.Mode === cur.Mode);

            if (shippingTypeIndex !== -1) {
                acc[shippingTypeIndex].Options.push(cur);
            } else {
                acc.push({
                    Mode: cur.Mode,
                    ModeName: cur.TModeName,
                    Options: [cur],
                });
            }
            return acc;
        }, []);
    }, []);
    useOnClickOutside(optionsRef, (e) => {
        setVisibleOptions(false);
    });
    // end region

    // region form
    const { control, watch, setValue } = useFormContext<RateSearchFormData>();

    const {
        fields: shipmentDetails,
        append,
        remove,
        replace,
    } = useFieldArray({
        name: 'ShipmentDetail',
        control: control,
    });

    const isAdvanceSearch = watch('IsAdvanceSearch');
    const typeOfShipment = watch('TypeOfShipment');
    // end region

    // region services
    const { data: containerTypes } = useGetByViewContainerQuery({
        skip: false,
    });

    // region renders
    const renderShippingTypeIcon = (mode: string) => {
        return mode === 'Sea' ? (
            <FaShip />
        ) : mode === 'Rail' ? (
            <FaTrain />
        ) : mode === 'Air' ? (
            <FaPlane />
        ) : mode === 'Road' ? (
            <FaTruckMoving />
        ) : (
            <></>
        );
    };
    const renderShippingAndTransportTypeDisplayValue = () => {
        if (['FCL', 'FTL'].includes(watch('TypeOfShipment'))) {
            if (isAdvanceSearch) {
                return `${watch('TypeOfShipment')} x${numberFormat(
                    watch('ShipmentDetail')?.length || 0,
                )}/${numberFormat(
                    sumBy(watch('ShipmentDetail'), (item) => {
                        return parseInt(item.Weight || '');
                    }),
                )}KG`;
            } else {
                return `${watch('TypeOfShipment')}, ${watch('ContainerType')}, ${Math.ceil(
                    Number(watch('DayAHead') || '14') / 7,
                )} ${t('week')}`;
            }
        } else if (['LCL', 'LTL', 'SC'].includes(watch('TypeOfShipment'))) {
            // return `${watch('TypeOfShipment')}, ${watch('Weight')}mt/${watch('Volume')}m³`;
            if (isAdvanceSearch) {
                return `${watch('TypeOfShipment')}, ${numberFormat(
                    sumBy(watch('ShipmentDetail'), (item) => {
                        const length = parseInt(item.Length || '0');
                        const width = parseInt(item.Width || '0');
                        const height = parseInt(item.Height || '0');
                        const quantity = parseInt(item.Quantity || '0');

                        return length * width * height * quantity;
                    }),
                )}M³/${numberFormat(
                    sumBy(watch('ShipmentDetail'), (item) => {
                        return parseInt(item.Weight || '');
                    }),
                )}KG`;
            } else {
                return `${watch('TypeOfShipment')}, ${watch('Weight')}KG/${watch('Volume')}M³`;
            }
        } else if (watch('TypeOfShipment') === 'BULK') {
            return `${watch('TypeOfShipment')}, ${watch('GrossWeight')}mt`;
        } else if (watch('TypeOfShipment') === 'FTL') {
            return `${watch('TypeOfShipment')}`;
        }

        return `${watch('TypeOfShipment')}`;
    };
    // end region

    return (
        <div className='relative flex-1'>
            <Controller
                control={control}
                name='ShipmentDisplayInput'
                render={({ field: { ref } }) => {
                    return (
                        <Input
                            fullWidth
                            ref={ref}
                            size='lg'
                            startContent={renderShippingTypeIcon(watch('TransportationBy'))}
                            readOnly
                            value={renderShippingAndTransportTypeDisplayValue()}
                            classNames={{
                                label: 'text-gray-800',
                                input: 'bg-white text-gray-800 placeholder:text-gray-400 font-medium',
                                innerWrapper: 'bg-transparent',
                                inputWrapper:
                                    'rounded-xl md:shadow-none bg-white group-data-[focus=true]:bg-white group-data-[focus=true]:outline-primary !cursor-text',
                            }}
                            placeholder={'FCL, SEA, 2 weeks'}
                            onFocus={() => {
                                setVisibleOptions(true);
                            }}
                            onClick={() => {
                                setVisibleOptions(true);
                            }}
                        />
                    );
                }}
            />

            <div ref={optionsRef}>
                {visibleOptions && (
                    <div
                        className={`absolute bg-white z-dropdown top-full right-0 left-0 md:left-auto rounded-xl shadow-1 mt-2 transition-all max-w-screen-xl ${
                            isAdvanceSearch ? 'md:w-[780px] lg:w-[900px]' : 'md:w-80'
                        }`}
                    >
                        {/* Collapse Expand Btn */}
                        <div className='absolute right-4 top-4'>
                            <Button
                                onPress={() => {
                                    setValue('IsAdvanceSearch', !isAdvanceSearch);
                                }}
                                isIconOnly
                                size='sm'
                                variant='light'
                                radius='sm'
                                className='hidden md:flex'
                            >
                                {isAdvanceSearch ? <BiCollapse size={20} /> : <BiExpand size={20} />}
                            </Button>
                        </div>
                        {/* Collapse Expand Btn */}

                        {optionsRef?.current && (
                            <div className='grid grid-cols-12 gap-4 pt-4 md:pt-10 pb-6 px-4'>
                                {/* <div className={'col-span-12'}>
                            <Controller
                                control={control}
                                name='DayAHead'
                                rules={{}}
                                render={({ field: { onChange, name, onBlur, ref, value, disabled } }) => {
                                    return (
                                        <Select
                                            ref={ref}
                                            name={name}
                                            onChange={(value) => {
                                                onChange(value);
                                            }}
                                            size='md'
                                            selectedKeys={new Set([value || ''])}
                                            disabled={disabled}
                                            items={containerTypes?.data?.Data || []}
                                            label={t('weekAHead')}
                                            placeholder={t('weekAHead')}
                                            labelPlacement='outside'
                                            variant='bordered'
                                            radius='sm'
                                            color='primary'
                                            classNames={{
                                                label: 'text-xs font-bold text-gray-800',
                                                trigger: 'border-1',
                                            }}
                                        >
                                            {(option) => {
                                                return (
                                                    <SelectItem
                                                        key={option.Code}
                                                        textValue={option.Code}
                                                        className='overflow-hidden'
                                                    >
                                                        <span className='ps-3 text-small text-ellipsis whitespace-nowrap overflow-hidden'>{`${option.Code}`}</span>
                                                    </SelectItem>
                                                );
                                            }}
                                        </Select>
                                    );
                                }}
                            />
                        </div> */}
                                <div
                                    className={
                                        isAdvanceSearch
                                            ? 'col-span-12 md:col-span-4 flex flex-col gap-4'
                                            : 'col-span-12 flex flex-col gap-4'
                                    }
                                >
                                    <Controller
                                        control={control}
                                        name='TypeOfShipment'
                                        rules={{}}
                                        render={({ field: { onChange, name, ref, value, disabled } }) => {
                                            return (
                                                <Select
                                                    ref={ref}
                                                    id='transportation-by-id'
                                                    name={name}
                                                    onSelectionChange={({ currentKey }) => {
                                                        if (currentKey) {
                                                            const [Mode, Type] = currentKey.split('-');
                                                            // console.log(Mode, Type);

                                                            setValue('TransportationBy', Mode);

                                                            if (Type === 'FCL') {
                                                                if (isAdvanceSearch) {
                                                                    replace([
                                                                        {
                                                                            ContainerType: '20DC',
                                                                            Weight: '18000',
                                                                            Quantity: '1',
                                                                        },
                                                                    ]);
                                                                } else {
                                                                    setValue('ContainerType', '20DC');
                                                                }
                                                            } else if (['LCL', 'SC'].includes(Type)) {
                                                                if (isAdvanceSearch) {
                                                                    replace([
                                                                        {
                                                                            Length: '10',
                                                                            Width: '10',
                                                                            Height: '10',
                                                                            Weight: '100',
                                                                            Quantity: '1',
                                                                        },
                                                                    ]);
                                                                } else {
                                                                    setValue('Weight', '1');
                                                                    setValue('Volume', '1');
                                                                }
                                                            }

                                                            onChange(Type);
                                                        }
                                                    }}
                                                    startContent={renderShippingTypeIcon(watch('TransportationBy'))}
                                                    size='md'
                                                    value={value}
                                                    selectedKeys={new Set([`${watch('TransportationBy')}-${value}`])}
                                                    disabled={disabled}
                                                    items={groupShippingTypes}
                                                    label={t('shippingType')}
                                                    placeholder={t('shippingType')}
                                                    labelPlacement='outside'
                                                    variant='bordered'
                                                    radius='sm'
                                                    color='primary'
                                                    classNames={{
                                                        label: 'text-xs font-bold text-gray-800',
                                                        trigger: 'border-1 w-full',
                                                        // popoverContent: 'bg-primary',
                                                    }}
                                                    popoverProps={{
                                                        portalContainer: optionsRef.current ?? undefined,
                                                    }}
                                                    fullWidth
                                                >
                                                    {(group) => {
                                                        return (
                                                            <SelectSection
                                                                title={
                                                                    (
                                                                        <div className='flex gap-2 items-center'>
                                                                            {renderShippingTypeIcon(group.Mode)}
                                                                            <span className='text-xs font-bold text-gray-800 uppercase'>
                                                                                {t(group.ModeName)}
                                                                            </span>
                                                                        </div>
                                                                    ) as unknown as string
                                                                }
                                                                classNames={{
                                                                    base: 'mb-0',
                                                                }}
                                                                key={group.Mode}
                                                            >
                                                                {group?.Options?.map((shippingType) => {
                                                                    return (
                                                                        <SelectItem
                                                                            textValue={`${t(shippingType?.TName)} - (${
                                                                                shippingType.Type
                                                                            })`}
                                                                            key={`${group.Mode}-${shippingType.Type}`}
                                                                            isDisabled={shippingType?.IsDisabled}
                                                                        >
                                                                            <div className='flex gap-2 items-center ps-3'>
                                                                                <div className='flex flex-col'>
                                                                                    <div className='flex gap-1 items-center'>
                                                                                        <span className='text-small'>
                                                                                            {t(shippingType.TName)} (
                                                                                            {shippingType.Type})
                                                                                        </span>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </SelectItem>
                                                                    );
                                                                })}
                                                            </SelectSection>
                                                        );
                                                    }}
                                                </Select>
                                            );
                                        }}
                                    />

                                    <Controller
                                        control={control}
                                        name='DayAHead'
                                        rules={{}}
                                        render={({ field: { onChange, name, onBlur, ref, value, disabled } }) => {
                                            return (
                                                <Select
                                                    ref={ref}
                                                    name={name}
                                                    onChange={(value) => {
                                                        onChange(value);
                                                    }}
                                                    size='md'
                                                    selectedKeys={new Set([value || ''])}
                                                    disabled={disabled}
                                                    items={dayAheadOptions}
                                                    label={t('weekAHead')}
                                                    placeholder={t('weekAHead')}
                                                    labelPlacement='outside'
                                                    variant='bordered'
                                                    radius='sm'
                                                    color='primary'
                                                    classNames={{
                                                        label: 'text-xs font-bold text-gray-800',
                                                        trigger: 'border-1',
                                                    }}
                                                    popoverProps={{
                                                        portalContainer: optionsRef.current ?? undefined,
                                                    }}
                                                >
                                                    {(option) => {
                                                        return (
                                                            <SelectItem
                                                                itemRef='hi'
                                                                key={option.Value}
                                                                textValue={t(option.TName)}
                                                                className='overflow-hidden'
                                                            >
                                                                <span className='ps-3 text-small text-ellipsis whitespace-nowrap overflow-hidden'>{`${t(
                                                                    option.TName,
                                                                )}`}</span>
                                                            </SelectItem>
                                                        );
                                                    }}
                                                </Select>
                                            );
                                        }}
                                    />

                                    {isAdvanceSearch && (
                                        <div className='flex-1 flex items-end'>
                                            <div className='flex items-center gap-2'>
                                                <span className='text-sm text-gray-500'>{t('shipmentTotal')}:</span>
                                                {watch('TypeOfShipment') === 'FCL' ? (
                                                    <span className='font-semibold text-sm text-gray-800'>
                                                        x{numberFormat(watch('ShipmentDetail')?.length || 0)},{' '}
                                                        {numberFormat(
                                                            sumBy(watch('ShipmentDetail'), (item) => {
                                                                return parseInt(item.Weight || '');
                                                            }),
                                                        )}
                                                        KG
                                                    </span>
                                                ) : (
                                                    <span className='font-semibold text-sm text-gray-800'>
                                                        {numberFormat(
                                                            sumBy(watch('ShipmentDetail'), (item) => {
                                                                const length = parseInt(item.Length || '0');
                                                                const width = parseInt(item.Width || '0');
                                                                const height = parseInt(item.Height || '0');
                                                                const quantity = parseInt(item.Quantity || '0');

                                                                return length * width * height * quantity;
                                                            }),
                                                        )}
                                                        M³,{' '}
                                                        {numberFormat(
                                                            sumBy(watch('ShipmentDetail'), (item) => {
                                                                return parseInt(item.Weight || '');
                                                            }),
                                                        )}
                                                        KG
                                                    </span>
                                                )}
                                            </div>
                                        </div>
                                    )}
                                </div>
                                <div
                                    className={`${
                                        isAdvanceSearch ? 'flex' : 'hidden'
                                    } col-span-12 md:col-span-8 flex flex-col gap-8 md:gap-3 w-full justify-start`}
                                >
                                    {shipmentDetails?.map((row, rowIndex) => {
                                        return (
                                            <div key={row.id} className='grid grid-cols-12 gap-3 items-end'>
                                                <div className={`col-span-12 md:col-span-5`}>
                                                    {['FCL', 'FTL'].includes(typeOfShipment) && (
                                                        <Controller
                                                            control={control}
                                                            name={`ShipmentDetail.${rowIndex}.ContainerType`}
                                                            rules={{}}
                                                            render={({
                                                                field: { onChange, name, onBlur, ref, value, disabled },
                                                            }) => {
                                                                return (
                                                                    <Select
                                                                        ref={ref}
                                                                        name={name}
                                                                        onChange={(value) => {
                                                                            onChange(value);
                                                                        }}
                                                                        size='md'
                                                                        selectedKeys={new Set([value || ''])}
                                                                        disabled={disabled}
                                                                        items={containerTypes?.data?.Data || []}
                                                                        label={t('containerType')}
                                                                        placeholder={t('containerType')}
                                                                        labelPlacement='outside'
                                                                        variant='bordered'
                                                                        radius='sm'
                                                                        color='primary'
                                                                        classNames={{
                                                                            label: 'text-xs font-bold text-gray-800',
                                                                            trigger: 'border-1',
                                                                        }}
                                                                        fullWidth
                                                                    >
                                                                        {(option) => {
                                                                            return (
                                                                                <SelectItem
                                                                                    key={option.Code}
                                                                                    textValue={option.Code}
                                                                                >
                                                                                    <span className='ps-3 text-small'>
                                                                                        {option.Code}
                                                                                    </span>
                                                                                </SelectItem>
                                                                            );
                                                                        }}
                                                                    </Select>
                                                                );
                                                            }}
                                                        />
                                                    )}

                                                    {['SC', 'LCL'].includes(typeOfShipment) && (
                                                        <>
                                                            {/* Dimensions */}
                                                            <Controller
                                                                control={control}
                                                                name={`ShipmentDetail.${rowIndex}.Width`}
                                                                rules={{}}
                                                                render={({
                                                                    field: {
                                                                        onChange,
                                                                        name,
                                                                        onBlur,
                                                                        ref,
                                                                        value,
                                                                        disabled,
                                                                    },
                                                                    field,
                                                                }) => {
                                                                    return (
                                                                        <Input
                                                                            {...field}
                                                                            label={t('dimensions')}
                                                                            size='md'
                                                                            labelPlacement='outside'
                                                                            placeholder={'W'}
                                                                            variant='bordered'
                                                                            radius='sm'
                                                                            color='primary'
                                                                            type='number'
                                                                            classNames={{
                                                                                label: 'text-xs font-bold text-gray-800',
                                                                                inputWrapper:
                                                                                    'border-1 focus-within:border-primary focus-within:hover:border-primary',
                                                                                input: 'text-center !ps-1 !pe-1',
                                                                            }}
                                                                            startContent={
                                                                                <Controller
                                                                                    control={control}
                                                                                    name={`ShipmentDetail.${rowIndex}.Length`}
                                                                                    rules={{}}
                                                                                    render={({ field }) => {
                                                                                        return (
                                                                                            <Input
                                                                                                {...field}
                                                                                                size='md'
                                                                                                labelPlacement='outside'
                                                                                                placeholder={'L'}
                                                                                                variant='bordered'
                                                                                                radius='sm'
                                                                                                type='number'
                                                                                                color='primary'
                                                                                                classNames={{
                                                                                                    inputWrapper:
                                                                                                        'border-none shadow-none px-0',
                                                                                                    input: 'text-center !pe-1',
                                                                                                }}
                                                                                                endContent={
                                                                                                    <AiOutlineClose
                                                                                                        size={10}
                                                                                                        className='shrink-0 text-gray-500'
                                                                                                    />
                                                                                                }
                                                                                            />
                                                                                        );
                                                                                    }}
                                                                                />
                                                                            }
                                                                            endContent={
                                                                                <Controller
                                                                                    control={control}
                                                                                    name={`ShipmentDetail.${rowIndex}.Height`}
                                                                                    rules={{}}
                                                                                    render={({ field }) => {
                                                                                        return (
                                                                                            <Input
                                                                                                {...field}
                                                                                                size='md'
                                                                                                labelPlacement='outside'
                                                                                                placeholder={'H'}
                                                                                                variant='bordered'
                                                                                                radius='sm'
                                                                                                type='number'
                                                                                                color='primary'
                                                                                                classNames={{
                                                                                                    inputWrapper:
                                                                                                        'border-none shadow-none px-0',
                                                                                                    input: 'text-center !ps-1',
                                                                                                }}
                                                                                                startContent={
                                                                                                    <AiOutlineClose
                                                                                                        size={10}
                                                                                                        className='shrink-0 text-gray-500'
                                                                                                    />
                                                                                                }
                                                                                            />
                                                                                        );
                                                                                    }}
                                                                                />
                                                                            }
                                                                        />
                                                                    );
                                                                }}
                                                            />
                                                            {/* Dimensions */}
                                                        </>
                                                    )}
                                                </div>
                                                <div className={`col-span-12 md:col-span-7`}>
                                                    <div className='flex items-end gap-3'>
                                                        <div className='flex-1'>
                                                            {/* Weight */}
                                                            {['LCL', 'LTL', 'SC', 'FCL', 'FTL'].includes(
                                                                typeOfShipment,
                                                            ) && (
                                                                <Controller
                                                                    control={control}
                                                                    name={`ShipmentDetail.${rowIndex}.Weight`}
                                                                    rules={{}}
                                                                    render={({ field }) => {
                                                                        return (
                                                                            <Input
                                                                                {...field}
                                                                                label={t('weight')}
                                                                                size='md'
                                                                                labelPlacement='outside'
                                                                                placeholder={t('weight')}
                                                                                variant='bordered'
                                                                                radius='sm'
                                                                                inputMode='numeric'
                                                                                type='number'
                                                                                color='primary'
                                                                                classNames={{
                                                                                    label: 'text-xs font-bold text-gray-800',
                                                                                    inputWrapper: 'border-1',
                                                                                }}
                                                                                fullWidth
                                                                            />
                                                                        );
                                                                    }}
                                                                />
                                                            )}
                                                            {/* Weight */}
                                                        </div>

                                                        {/* Quantity */}
                                                        {['FCL', 'SC', 'LCL', 'FTL'].includes(typeOfShipment) && (
                                                            <div className='flex-1'>
                                                                <Controller
                                                                    control={control}
                                                                    name={`ShipmentDetail.${rowIndex}.Quantity`}
                                                                    rules={{}}
                                                                    render={({ field, field: { onChange } }) => {
                                                                        const numberValue = parseInt(
                                                                            field.value || '0',
                                                                        );
                                                                        return (
                                                                            <Input
                                                                                {...field}
                                                                                label={t('units')}
                                                                                startContent={
                                                                                    <Button
                                                                                        className='md:!w-6 md:!h-6 md:!min-w-6'
                                                                                        isIconOnly
                                                                                        size='sm'
                                                                                        radius='full'
                                                                                        isDisabled={numberValue <= 1}
                                                                                        onPress={() => {
                                                                                            onChange(
                                                                                                (
                                                                                                    numberValue - 1
                                                                                                ).toString(),
                                                                                            );
                                                                                        }}
                                                                                    >
                                                                                        <AiOutlineMinus
                                                                                            size={14}
                                                                                            strokeWidth={1}
                                                                                        />
                                                                                    </Button>
                                                                                }
                                                                                endContent={
                                                                                    <Button
                                                                                        className='md:!w-6 md:!h-6 md:!min-w-6'
                                                                                        isIconOnly
                                                                                        size='sm'
                                                                                        radius='full'
                                                                                        onPress={() => {
                                                                                            onChange(
                                                                                                (
                                                                                                    numberValue + 1
                                                                                                ).toString(),
                                                                                            );
                                                                                        }}
                                                                                    >
                                                                                        <AiOutlinePlus
                                                                                            size={14}
                                                                                            strokeWidth={1}
                                                                                        />
                                                                                    </Button>
                                                                                }
                                                                                type='number'
                                                                                size='md'
                                                                                labelPlacement='outside'
                                                                                placeholder={t('units')}
                                                                                variant='bordered'
                                                                                radius='sm'
                                                                                color='primary'
                                                                                classNames={{
                                                                                    label: 'text-xs font-bold text-gray-800',
                                                                                    inputWrapper: 'border-1',
                                                                                    input: 'text-center',
                                                                                }}
                                                                                fullWidth
                                                                            />
                                                                        );
                                                                    }}
                                                                />
                                                            </div>
                                                        )}
                                                        {/* Quantity */}

                                                        <div className=''>
                                                            <Button
                                                                onPress={() => {
                                                                    remove(rowIndex);
                                                                }}
                                                                isIconOnly
                                                                variant='light'
                                                                size='md'
                                                                color='default'
                                                                radius='sm'
                                                                className='text-gray-600'
                                                                isDisabled={shipmentDetails?.length === 1}
                                                            >
                                                                <IoTrashBin size={16} className='' />
                                                            </Button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        );
                                    })}

                                    <Button
                                        variant='light'
                                        startContent={<FaPlus />}
                                        color='primary'
                                        onPress={() => {
                                            if (['FCL', 'FTL']?.includes(typeOfShipment)) {
                                                append({
                                                    ContainerType: '20DC',
                                                    Quantity: '1',
                                                    Weight: '18000',
                                                });
                                            } else if (typeOfShipment === 'LCL') {
                                                append({
                                                    Length: '10',
                                                    Width: '10',
                                                    Height: '10',
                                                    Weight: '100',
                                                    Quantity: '1',
                                                });
                                            } else if (typeOfShipment === 'SC') {
                                                append({
                                                    Length: '10',
                                                    Width: '10',
                                                    Height: '10',
                                                    Weight: '100',
                                                    Quantity: '1',
                                                });
                                            }
                                        }}
                                    >
                                        {t('addRow')}
                                    </Button>
                                </div>
                                <div className={isAdvanceSearch ? 'hidden' : 'col-span-12 flex flex-col relative'}>
                                    <Link
                                        href='/reference/container'
                                        className='flex items-center text-xs font-bold text-gray-800 gap-2 cursor-pointer absolute top-0 left-0 hover:underline'
                                    >
                                        {t('containerType')} <IoInformationCircleOutline size={18} />
                                    </Link>

                                    {/* container type */}
                                    {['FCL', 'FTL'].includes(typeOfShipment) && (
                                        <Controller
                                            control={control}
                                            name='ContainerType'
                                            rules={{}}
                                            render={({ field: { onChange, name, onBlur, ref, value, disabled } }) => {
                                                return (
                                                    <Select
                                                        ref={ref}
                                                        name={name}
                                                        onChange={(value) => {
                                                            onChange(value);
                                                        }}
                                                        size='md'
                                                        selectedKeys={new Set([value || ''])}
                                                        disabled={disabled}
                                                        items={containerTypes?.data?.Data || []}
                                                        label={
                                                            ' '
                                                            // <div className='flex items-center gap-2 cursor-pointer'>
                                                            //     {t('containerType')}{' '}
                                                            //     {/* <IoInformationCircleOutline
                                                            //         size={18}
                                                            //         className='text-primary !cursor-pointer'
                                                            //     /> */}
                                                            // </div>
                                                        }
                                                        placeholder={t('containerType')}
                                                        labelPlacement='outside'
                                                        variant='bordered'
                                                        radius='sm'
                                                        color='primary'
                                                        classNames={{
                                                            label: 'text-xs font-bold text-gray-800',
                                                            trigger: 'border-1',
                                                        }}
                                                        popoverProps={{
                                                            portalContainer: optionsRef.current ?? undefined,
                                                        }}
                                                    >
                                                        {(option) => {
                                                            const detail = option.Detail[0];
                                                            return (
                                                                <SelectItem
                                                                    key={option.Code}
                                                                    textValue={option.Code}
                                                                    className='overflow-hidden relative'
                                                                >
                                                                    <Tooltip
                                                                        placement={'left'}
                                                                        offset={18}
                                                                        content={
                                                                            <div className='flex justify-between gap-2 sm:gap-4 p-2'>
                                                                                <div className='flex flex-col gap-2 font-semibold'>
                                                                                    <span>
                                                                                        {tReference(
                                                                                            'container.dimensions.insideLength',
                                                                                        )}
                                                                                        :
                                                                                    </span>
                                                                                    <span>
                                                                                        {tReference(
                                                                                            'container.dimensions.insideWidth',
                                                                                        )}
                                                                                        :
                                                                                    </span>
                                                                                    <span>
                                                                                        {tReference(
                                                                                            'container.dimensions.insideHeight',
                                                                                        )}
                                                                                        :
                                                                                    </span>
                                                                                    <span>
                                                                                        {tReference(
                                                                                            'container.dimensions.doorWidth',
                                                                                        )}
                                                                                        :
                                                                                    </span>
                                                                                    <span>
                                                                                        {tReference(
                                                                                            'container.dimensions.doorHeight',
                                                                                        )}
                                                                                        :
                                                                                    </span>
                                                                                    <span>
                                                                                        {tReference(
                                                                                            'container.dimensions.capacity',
                                                                                        )}
                                                                                        :
                                                                                    </span>
                                                                                    <span>
                                                                                        {tReference(
                                                                                            'container.dimensions.tareWeight',
                                                                                        )}
                                                                                        :
                                                                                    </span>
                                                                                    <span>
                                                                                        {tReference(
                                                                                            'container.dimensions.maxCargoWeight',
                                                                                        )}
                                                                                        :
                                                                                    </span>
                                                                                </div>
                                                                                <div className='flex flex-col gap-2'>
                                                                                    <span>
                                                                                        {detail?.INSIDE_LENGTH || '...'}
                                                                                    </span>
                                                                                    <span>
                                                                                        {detail?.INSIDE_WIDTH || '...'}
                                                                                    </span>
                                                                                    <span>
                                                                                        {detail?.INSIDE_HEIGHT || '...'}
                                                                                    </span>
                                                                                    <span>
                                                                                        {detail?.DOOR_WIDTH || '...'}
                                                                                    </span>
                                                                                    <span>
                                                                                        {detail?.DOOR_HEIGHT || '...'}
                                                                                    </span>
                                                                                    <span>
                                                                                        {detail?.CAPACITY || '...'}
                                                                                    </span>
                                                                                    <span>
                                                                                        {detail?.TARE_WEIGHT || '...'}
                                                                                    </span>
                                                                                    <span>
                                                                                        {detail?.MAX_CARGO_WEIGHT ||
                                                                                            '...'}
                                                                                    </span>
                                                                                </div>
                                                                            </div>
                                                                        }
                                                                    >
                                                                        <div className='flex flex-col gap-1'>
                                                                            <div className='text-small font-medium text-ellipsis whitespace-nowrap overflow-hidden'>
                                                                                {option.Code}
                                                                            </div>
                                                                            {detail ? (
                                                                                <>
                                                                                    <span className='flex justify-between items-center gap-2 text-gray-500 text-xs'>
                                                                                        {tReference(
                                                                                            'container.dimensionsAcronym.capacity',
                                                                                        )}
                                                                                        : {detail?.CAPACITY || '...'}
                                                                                        {' | '}
                                                                                        {tReference(
                                                                                            'container.dimensionsAcronym.maxWeight',
                                                                                        )}
                                                                                        :{' '}
                                                                                        {detail?.MAX_CARGO_WEIGHT ||
                                                                                            '...'}
                                                                                    </span>
                                                                                </>
                                                                            ) : (
                                                                                <></>
                                                                            )}
                                                                        </div>
                                                                    </Tooltip>
                                                                </SelectItem>
                                                            );
                                                        }}
                                                    </Select>
                                                );
                                            }}
                                        />
                                    )}
                                    {/* container type */}

                                    {/* weight */}
                                    {['LCL', 'LTL', 'SC'].includes(typeOfShipment) && (
                                        <Controller
                                            control={control}
                                            name='Weight'
                                            rules={{}}
                                            render={({ field }) => {
                                                return (
                                                    <Input
                                                        {...field}
                                                        label={t('weight')}
                                                        size='md'
                                                        labelPlacement='outside'
                                                        placeholder={t('weight')}
                                                        variant='bordered'
                                                        radius='sm'
                                                        color='primary'
                                                        classNames={{
                                                            label: 'text-xs font-bold text-gray-800',
                                                            inputWrapper: 'border-1',
                                                        }}
                                                    />
                                                );
                                            }}
                                        />
                                    )}
                                    {/* weight */}

                                    {/* volume */}
                                    {['LCL', 'LTL', 'SC'].includes(typeOfShipment) && (
                                        <Controller
                                            control={control}
                                            name='Volume'
                                            rules={{}}
                                            render={({ field }) => {
                                                return (
                                                    <Input
                                                        {...field}
                                                        label={t('volume')}
                                                        size='md'
                                                        labelPlacement='outside'
                                                        placeholder={t('volume')}
                                                        variant='bordered'
                                                        radius='sm'
                                                        color='primary'
                                                        classNames={{
                                                            label: 'text-xs font-bold text-gray-800',
                                                            inputWrapper: 'border-1',
                                                        }}
                                                    />
                                                );
                                            }}
                                        />
                                    )}
                                    {/* volume */}

                                    {/* gross weight */}

                                    {typeOfShipment === 'BULK' && (
                                        <Controller
                                            control={control}
                                            name='GrossWeight'
                                            rules={{}}
                                            render={({ field }) => {
                                                return (
                                                    <Input
                                                        {...field}
                                                        label={t('grossWeight')}
                                                        size='md'
                                                        labelPlacement='outside'
                                                        placeholder={t('grossWeight')}
                                                        variant='bordered'
                                                        radius='sm'
                                                        color='primary'
                                                        classNames={{
                                                            label: 'text-xs font-bold text-gray-800',
                                                            inputWrapper: 'border-1',
                                                        }}
                                                    />
                                                );
                                            }}
                                        />
                                    )}
                                    {/* gross weight */}
                                </div>
                                {isAdvanceSearch && (
                                    <div className='col-span-12'>
                                        <Divider className='' />
                                        <div className='flex gap-6 pt-4 flex-wrap'>
                                            <Controller
                                                control={control}
                                                name='Insurance'
                                                render={({ field: { value, onChange } }) => {
                                                    return (
                                                        <Checkbox
                                                            checked={value}
                                                            isSelected={value}
                                                            onValueChange={(value) => {
                                                                onChange(value);
                                                            }}
                                                            radius='sm'
                                                            classNames={{
                                                                label: 'flex items-center text-sm',
                                                                wrapper: 'after:!bg-gray-800',
                                                                base: '[&>span:nth-child(2):before]:!border-1',
                                                            }}
                                                        >
                                                            {t('insurance')}
                                                        </Checkbox>
                                                    );
                                                }}
                                            />

                                            <Controller
                                                control={control}
                                                name='CustomsClearance'
                                                render={({ field: { value, onChange } }) => {
                                                    return (
                                                        <Checkbox
                                                            checked={value}
                                                            isSelected={value}
                                                            onValueChange={(value) => {
                                                                onChange(value);
                                                            }}
                                                            radius='sm'
                                                            classNames={{
                                                                label: 'flex items-center text-sm',
                                                                wrapper: 'after:!bg-gray-800',
                                                                base: '[&>span:nth-child(2):before]:!border-1',
                                                            }}
                                                        >
                                                            {t('customsClearance')}
                                                        </Checkbox>
                                                    );
                                                }}
                                            />

                                            <Controller
                                                control={control}
                                                name='OnlyLocalCharges'
                                                render={({ field: { value, onChange } }) => {
                                                    return (
                                                        <Checkbox
                                                            checked={value}
                                                            isSelected={value}
                                                            onValueChange={(value) => {
                                                                onChange(value);
                                                            }}
                                                            radius='sm'
                                                            classNames={{
                                                                label: 'flex items-center text-sm',
                                                                wrapper: 'after:!bg-gray-800',
                                                                base: '[&>span:nth-child(2):before]:!border-1',
                                                            }}
                                                        >
                                                            {t('onlyLocalCharges')}
                                                        </Checkbox>
                                                    );
                                                }}
                                            />

                                            <Controller
                                                control={control}
                                                name='InspectionServices'
                                                render={({ field: { value, onChange } }) => {
                                                    return (
                                                        <Checkbox
                                                            checked={value}
                                                            isSelected={value}
                                                            onValueChange={(value) => {
                                                                onChange(value);
                                                            }}
                                                            radius='sm'
                                                            classNames={{
                                                                label: 'flex items-center text-sm',
                                                                wrapper: 'after:!bg-gray-800',
                                                                base: '[&>span:nth-child(2):before]:!border-1',
                                                            }}
                                                        >
                                                            {t('inspectionServices')}
                                                        </Checkbox>
                                                    );
                                                }}
                                            />
                                        </div>
                                    </div>
                                )}
                            </div>
                        )}
                    </div>
                )}
            </div>
        </div>
    );
};

export default ShippingAndTransportType;
